import React, { useState } from "react";
import { Link } from "gatsby";
import { useThemeState } from "@context/theme";
import { get } from "lodash";
import * as styles from "./../index.module.scss";

const Item = ({ node, showExtended, setShowExtended = () => {} }) => {
  const [open, setOpen] = useState(false);
  const { data, uid } = node;
  const { title, items = [] } = data;
  const { openViewer } = useThemeState();

  const toggleOpen = () => {
    setOpen(!open);
  };
  const toggleShowExtended = () => {
    setShowExtended(!showExtended);
  };

  const active = items.length > 1;

  const hide = [2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011];

  if (!showExtended && hide.indexOf(parseInt(title.text)) > -1) {
    if (parseInt(title.text) === 2019) {
      return (
        <li style={{ marginBottom: "10px" }}>
          <h2>
            <button onClick={toggleShowExtended}>2019 - 2011</button>
          </h2>
        </li>
      );
    }
    return <div />;
  }
  return (
    <>
      <li className={open && active ? styles.active : ""}>
        <h2>
          <button onClick={toggleOpen}>{title?.text}</button>
        </h2>
      </li>

      <ul
        className={styles.albumsListContents}
        style={{
          height: open && active ? "auto" : "0px",
          margin: open && active ? "40px 0 40px 0" : "0 0 10px 0",
        }}
      >
        {items.map(({ image }, index) => {
          const imageSrc = get(image, "localFile.square.resize.src", false);
          const imageSquareSrc = get(image, "thumbnails.Square.url", false);

          const handleOpen = () => {
            openViewer(uid, index);
          };
          return (
            <li key={imageSrc}>
              <button onClick={handleOpen}>
                {(imageSrc || imageSquareSrc) && (
                  <img
                    style={{ pointerEvents: "none" }}
                    draggable={false}
                    src={imageSquareSrc || imageSrc}
                  />
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Item;
